<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items marginBottom20">

          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">优惠券编号: </span>
            <div class="width200">
              <el-input
                v-model="searchData.code"
                placeholder="请输入"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">优惠券名称: </span>
            <div class="width200">
              <el-input
                v-model="searchData.name"
                placeholder="请输入"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">优惠券类型: </span>
            <div class="width200">
              <el-select
                class="width200"
                v-model="searchData.type"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择"
                ></el-option>
                <el-option
                  :value="2"
                  label="代金"
                ></el-option>
                <el-option
                  :value="1"
                  label="满减"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">上架状态: </span>
            <div class="width200">
              <el-select
                class="width200"
                v-model="searchData.state"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择"
                ></el-option>
                <el-option
                  :value="1"
                  label="上架"
                ></el-option>
                <el-option
                  :value="2"
                  label="下架"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">

            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>

          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-plus"
              @click="handleCreate"
            >上架优惠券</el-button>
          </div>

        </div>
      </template>

      <template
        slot="useScope"
        slot-scope="{ row }"
      >
        <div>
          <span v-if="row.useScope == 1">全区域</span>
          <el-button
            v-if="row.useScope == 2"
            type="text"
            @click="onLookUseHandle(row)"
          >查看</el-button>
        </div>

      </template>
      <template
        slot="state"
        slot-scope="{ row }"
      >
        <el-switch
          v-model="row.state"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event,row.id)"
        ></el-switch>
      </template>
      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          v-if="row.state == 2"
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      :title="titName"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >

        <template slot="couponId">
          <el-select
            v-model="form.couponId"
            placeholder="请选择优惠劵编号"
            @change="getCouponNameHandle"
            filterable=true
          >
            <el-option
              v-for="item in couponList"
              :key="item.id"
              :label="item.code"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>

        <template slot="couponName">
          {{ form.couponName || '选择优惠券后自动带出' }}
        </template>

        <template slot="price">
          <el-input
            v-model="form.price"
            placeholder="请输入"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event,'price',999999.99)"
            type="text"
            clearable
          />
        </template>

        <template slot="sort">
          <el-input
            v-model="form.sort"
            placeholder="请输入"
            onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
            @blur="salaryChange($event,'sort',999999)"
            type="text"
            clearable
          />
        </template>

        <template slot="state">
          <el-switch
            v-model="form.state"
            :inactive-value="2"
            :active-value="1"
          ></el-switch>
        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      title="可用区域"
      :visible.sync="dialogTableVisibleUse"
    >
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="dialogTableVisibleUse = false"
        >确 定</el-button>
      </div>
      <el-table :data="useList">
        <el-table-column
          property="code"
          label="区域编号"
        ></el-table-column>
        <el-table-column
          property="name"
          label="姓名"
        ></el-table-column>
        <el-table-column
          property="state"
          label="地址"
        >
          <template slot-scope="scope">
            {{ scope.state==1?'正常':scope.state==2?'冻结':'' }}
          </template>
        </el-table-column>

      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  add,
  deleteNew,
  list,
  update,
  updateNew,
  view,
  getOnLookUseHandle,
  getCouponList,
} from "@/api/coupon/up";
import dayjs from "dayjs";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        type: "",
      },
      titName:'',
      page: {
        total: 0,
        currentPage: 1,
      },
      useList: [],
      tableData: [],
      couponList: [],
      showLoading: false,
      option: {
        title: "优惠券上架",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "优惠劵编号",
            prop: "code",
          },
          {
            label: "优惠劵名称",
            prop: "name",
          },
          {
            label: "优惠劵类型",
            prop: "type",
            formatter: function (row, column, cellValue) {
              return row.type === 1
                ? "满减"
                : row.type === 2
                ? "代金"
                : row.type;
            },
          },
          {
            label: "使用条件",
            prop: "useConditionFullPrice",
            formatter: function (row, column, cellValue) {
              return row.type == 1
                ? row.useConditionFullPrice == 0
                  ? "无条件"
                  : `满￥ ${cellValue}可用`
                : row.takePrice;
            },
          },
          {
            label: "有效期",
            prop: "validityStartTime",
            formatter: function (row, column, cellValue) {
              return row.overdueType == 1
                ? `${row.validityStartTime}~${row.validityEndTime}`
                : `领取后${row.comeNum}天生效，生效后${row.validityNum}天失效`;
            },
          },
          {
            label: "可使用时间",
            prop: "useConditionFullPrice",
            formatter: function (row, column, cellValue) {
              if (row.useStartTime.length > 5) {
                const useStartTime = row.useStartTime.slice(0, 5);
                const useEndTime = row.useEndTime.slice(0, 5);
                return `${useStartTime} ~ ${useEndTime}`;
              }
              return `${row.useStartTime} ~ ${row.useEndTime}`;
            },
          },
          {
            label: "可用区域",
            prop: "useScope",
          },
          {
            label: "优惠劵价格(元)",
            prop: "price",
          },
          {
            label: "实际销量",
            prop: "drawNum",
          },
          {
            label: "库存剩余(张)",
            prop: "stockNum",
          },
          {
            label: "上架状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "上架"
                : row.state === 2
                ? "下架"
                : row.state;
            },
          },
          {
            label: "排序值",
            prop: "sort",
          },
        ],
      },
      dialogTableVisibleUse: false,
      dialogVisible: false,
      form: {
        id: "",
        type: "",
        name: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "140",
        column: [
          {
            label: "优惠券编号",
            prop: "couponId",
            type: "select",
            dicData: [],
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择优惠券编号",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择优惠券编号"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "优惠券名称",
            prop: "couponName",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
          },
          {
            label: "优惠劵价格(元)",
            prop: "price",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入优惠劵价格",
                trigger: "blur",
              },
            ],
          },
          {
            label: "排序值",
            prop: "sort",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入排序值",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上架状态",
            prop: "state",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择上架状态",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.getCouponList();
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
         delete this.form[t]
      }
    },
    // 获取优惠劵列表
    getCouponList() {
      getCouponList().then((res) => {
        if (res.code === 200) {
          this.couponList = res.data;
          console.log(" this.couponList ", this.couponList);
        }
      });
    },
    getCouponNameHandle(e) {
      this.form.couponName = this.couponList.find((item) => item.id === e).name;
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      // console.log('添加')
      this.titName = '优惠券选择'
      this.dialogVisible = true;
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        type: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 编辑
    async handleEdit(row) {
      // console.log('编辑',row)
      this.titName = '上架编辑'
      const { data } = await view({
        id: row.id,
      });
      // console.log(data)
      this.form = data;
      console.log(this.couponList,data.couponId,row);
      this.form.couponName = row.name
      // this.form.couponName = this.couponList.find(
      //   (item) => item.id === data.couponId
      // ).name;
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //修改状态
    handleSwitch(e, id) {
      // console.log(e,id)
      updateNew({
        id: id,
        state: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
    //查看区域
    onLookUseHandle(row) {
      this.dialogTableVisibleUse = true;
      getOnLookUseHandle({
        id: row.id,
      }).then((res) => {
        if (res.code === 200) {
          this.useList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 提交
    submit(form, done) {
      // console.log(form)
      if (form.id) {
        update(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      } else {
        add(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
        name: "",
      };
    },
  },
};
</script>

<style scoped>
</style>